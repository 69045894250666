/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.nux-dot-tip::before, .nux-dot-tip::after {
  border-radius: 100%;
  content: " ";
  pointer-events: none;
  position: absolute; }

.nux-dot-tip::before {
  animation: nux-pulse 1.6s infinite cubic-bezier(0.17, 0.67, 0.92, 0.62);
  background: rgba(0, 115, 156, 0.9);
  height: 24px;
  left: -12px;
  top: -12px;
  transform: scale(0.33333);
  width: 24px; }

.nux-dot-tip::after {
  background: #00739c;
  height: 8px;
  left: -4px;
  top: -4px;
  width: 8px; }

@keyframes nux-pulse {
  100% {
    background: rgba(0, 115, 156, 0);
    transform: scale(1); } }

.nux-dot-tip .components-popover__content {
  padding: 5px 41px 5px 20px;
  width: 350px; }
  @media (min-width: 600px) {
    .nux-dot-tip .components-popover__content {
      width: 450px; } }
  .nux-dot-tip .components-popover__content .nux-dot-tip__disable {
    position: absolute;
    right: 0;
    top: 0; }

.nux-dot-tip.is-top {
  margin-top: -4px; }

.nux-dot-tip.is-bottom {
  margin-top: 4px; }

.nux-dot-tip.is-middle.is-left {
  margin-left: -4px; }

.nux-dot-tip.is-middle.is-right {
  margin-left: 4px; }

.nux-dot-tip.is-top .components-popover__content {
  margin-bottom: 20px; }

.nux-dot-tip.is-bottom .components-popover__content {
  margin-top: 20px; }

.nux-dot-tip.is-middle.is-left .components-popover__content {
  margin-right: 20px; }

.nux-dot-tip.is-middle.is-right .components-popover__content {
  margin-left: 20px; }

.nux-dot-tip:not(.is-mobile).is-left, .nux-dot-tip:not(.is-mobile).is-center, .nux-dot-tip:not(.is-mobile).is-right {
  z-index: 1000001; }
  @media (max-width: 600px) {
    .nux-dot-tip:not(.is-mobile).is-left .components-popover__content, .nux-dot-tip:not(.is-mobile).is-center .components-popover__content, .nux-dot-tip:not(.is-mobile).is-right .components-popover__content {
      align-self: end;
      left: 5px;
      margin: 20px 0 0 0;
      max-width: none !important;
      position: fixed;
      right: 5px;
      width: auto; } }

.nux-dot-tip.components-popover:not(.is-mobile):not(.is-middle).is-right .components-popover__content {
  /*!rtl:ignore*/
  margin-left: 0; }

.nux-dot-tip.components-popover:not(.is-mobile):not(.is-middle).is-left .components-popover__content {
  /*!rtl:ignore*/
  margin-right: 0; }

.nux-dot-tip.components-popover.edit-post-more-menu__content:not(.is-mobile):not(.is-middle).is-right .components-popover__content {
  /*!rtl:ignore*/
  margin-left: -12px; }

.nux-dot-tip.components-popover.edit-post-more-menu__content:not(.is-mobile):not(.is-middle).is-left .components-popover__content {
  /*!rtl:ignore*/
  margin-right: -12px; }
