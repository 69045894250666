/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.list-reusable-blocks-import-dropdown__content .components-popover__content {
  padding: 10px; }

.list-reusable-blocks-import-form__label {
  display: block;
  margin-bottom: 10px; }

.list-reusable-blocks-import-form__button {
  margin-top: 20px;
  float: right; }

.list-reusable-blocks-import-form .components-notice__content {
  margin: 0; }

.list-reusable-blocks__container {
  display: inline-flex;
  padding: 9px 0 4px;
  align-items: center;
  vertical-align: top; }
