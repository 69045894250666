/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.block-editor ul.wp-block-archives {
  padding-left: 2.5em; }

.wp-block-audio {
  margin-left: 0;
  margin-right: 0; }

.edit-post-visual-editor .block-library-block__reusable-block-container .block-editor-writing-flow__click-redirect {
  height: auto; }

.block-editor-block-list__block[data-type="core/button"][data-align="center"] {
  text-align: center; }
  .block-editor-block-list__block[data-type="core/button"][data-align="center"] div[data-block] {
    margin-left: auto;
    margin-right: auto; }

.block-editor-block-list__block[data-type="core/button"][data-align="right"] {
  /*!rtl:ignore*/
  text-align: right; }

.wp-block-button {
  position: relative; }
  .wp-block-button [contenteditable] {
    cursor: text; }
  .wp-block-button .block-editor-rich-text {
    display: inline-block; }
  .wp-block-button:not(.has-text-color):not(.is-style-outline) [data-rich-text-placeholder]::after {
    color: #fff; }
  .wp-block-button .block-editor-rich-text__editable:focus {
    box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a0d2;
    outline: 2px solid transparent;
    outline-offset: -2px; }
  .wp-block-button [data-rich-text-placeholder]::after {
    opacity: 0.8; }

.wp-block-button__inline-link {
  color: #555d66;
  height: 0;
  overflow: hidden;
  max-width: 290px; }
  .wp-block-button__inline-link-input__suggestions {
    max-width: 290px; }
  @media (min-width: 782px) {
    .wp-block-button__inline-link {
      max-width: 260px; }
      .wp-block-button__inline-link-input__suggestions {
        max-width: 260px; } }
  @media (min-width: 960px) {
    .wp-block-button__inline-link {
      max-width: 290px; }
      .wp-block-button__inline-link-input__suggestions {
        max-width: 290px; } }
  .is-selected .wp-block-button__inline-link,
  .is-typing .wp-block-button__inline-link {
    height: auto;
    overflow: visible;
    margin-top: 16px; }

div[data-type="core/button"] div[data-block] {
  display: table; }

.block-editor .wp-block-categories ul {
  padding-left: 2.5em; }
  .block-editor .wp-block-categories ul ul {
    margin-top: 6px; }

.wp-block-code .block-editor-plain-text {
  font-family: Menlo, Consolas, monaco, monospace;
  color: #23282d;
  /* Fonts smaller than 16px causes mobile safari to zoom. */
  font-size: 16px; }
  @media (min-width: 600px) {
    .wp-block-code .block-editor-plain-text {
      font-size: 13px; } }
  .wp-block-code .block-editor-plain-text:focus {
    box-shadow: none; }

.components-tab-button {
  display: inline-flex;
  align-items: flex-end;
  margin: 0;
  padding: 3px;
  background: none;
  outline: none;
  color: #555d66;
  cursor: pointer;
  position: relative;
  height: 36px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  font-weight: 500;
  border: 0; }
  .components-tab-button.is-active, .components-tab-button.is-active:hover {
    color: #fff; }
  .components-tab-button:disabled {
    cursor: default; }
  .components-tab-button > span {
    border: 1px solid transparent;
    padding: 0 6px;
    box-sizing: content-box;
    height: 28px;
    line-height: 28px; }
  .components-tab-button:hover > span,
  .components-tab-button:focus > span {
    color: #555d66; }
  .components-tab-button:not(:disabled).is-active > span,
  .components-tab-button:not(:disabled):hover > span,
  .components-tab-button:not(:disabled):focus > span {
    border: 1px solid #555d66; }
  .components-tab-button.is-active > span,
  .components-tab-button.is-active:hover > span {
    background-color: #555d66;
    color: #fff; }

.wp-block-columns .editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }
  .wp-block-columns .editor-block-list__layout .editor-block-list__block {
    max-width: none; }

[data-type="core/columns"][data-align="full"] .wp-block-columns > .editor-inner-blocks {
  padding-left: 14px;
  padding-right: 14px; }
  @media (min-width: 600px) {
    [data-type="core/columns"][data-align="full"] .wp-block-columns > .editor-inner-blocks {
      padding-left: 46px;
      padding-right: 46px; } }

.wp-block-columns {
  display: block; }
  .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 782px) {
      .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout {
        flex-wrap: nowrap; } }
    .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"],
    .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit,
    .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit > div[data-block],
    .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit .block-core-columns {
      display: flex;
      flex-direction: column;
      flex: 1; }
    .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] {
      padding-left: 0;
      padding-right: 0;
      margin-left: -14px;
      margin-right: -14px;
      min-width: 0;
      word-break: break-word;
      overflow-wrap: break-word;
      flex-basis: 100%; }
      @media (min-width: 600px) {
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] {
          flex-basis: calc(50% - (16px + 28px));
          flex-grow: 0;
          margin-left: 14px;
          margin-right: 14px; } }
      @media (min-width: 600px) {
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"]:nth-child(even) {
          margin-left: calc(32px + 14px); } }
      @media (min-width: 782px) {
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"]:not(:first-child) {
          margin-left: calc(32px + 14px); } }
      .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit {
        margin-top: 0;
        margin-bottom: 0; }
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit::before {
          left: 0;
          right: 0; }
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit > .editor-block-contextual-toolbar {
          margin-left: -1px; }
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit > [data-block] {
          margin-top: 0;
          margin-bottom: 0; }
        .wp-block-columns > .editor-inner-blocks > .editor-block-list__layout > [data-type="core/column"] > .editor-block-list__block-edit > div > .block-core-columns > .editor-inner-blocks {
          margin-top: -28px;
          margin-bottom: -28px; }

/**
 * Columns act as as a "passthrough container"
 * and therefore has its vertical margins/padding removed via negative margins
 * therefore we need to compensate for this here by doubling the spacing on the
 * vertical to ensure there is equal visual spacing around the inserter. Note there
 * is no formal API for a "passthrough" Block so this is an edge case overide
 */
[data-type="core/columns"] .block-list-appender {
  margin-top: 28px;
  margin-bottom: 28px; }

[data-type="core/columns"] [data-type="core/column"].is-selected .block-list-appender {
  margin: 14px 0; }

/**
 * Vertical Alignment Preview
 * note: specificity is important here to ensure individual
 * * columns alignment is prioritised over parent column alignment
 *
 */
.are-vertically-aligned-top .block-core-columns,
div.block-core-columns.is-vertically-aligned-top {
  justify-content: flex-start; }

.are-vertically-aligned-center .block-core-columns,
div.block-core-columns.is-vertically-aligned-center {
  justify-content: center; }

.are-vertically-aligned-bottom .block-core-columns,
div.block-core-columns.is-vertically-aligned-bottom {
  justify-content: flex-end; }

/**
 * Fixes single Column breadcrumb position.
 */
[data-type="core/column"] > .editor-block-list__block-edit > .editor-block-list__breadcrumb {
  left: -3px; }

/**
 * Make single Column overlay not extend past boundaries of parent
 */
.block-core-columns > .block-editor-inner-blocks.has-overlay::after {
  left: 0;
  right: 0; }

/**
 * Add extra padding when the parent block is selected, for easier interaction.
 */
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/columns"].is-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks,
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/columns"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks,
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/column"].is-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks,
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/column"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks {
  padding: 14px; }
  .block-editor-block-list__layout .block-editor-block-list__block[data-type="core/columns"].is-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks > .components-placeholder,
  .block-editor-block-list__layout .block-editor-block-list__block[data-type="core/columns"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks > .components-placeholder,
  .block-editor-block-list__layout .block-editor-block-list__block[data-type="core/column"].is-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks > .components-placeholder,
  .block-editor-block-list__layout .block-editor-block-list__block[data-type="core/column"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > div > .block-editor-inner-blocks > .components-placeholder {
    margin: -14px;
    width: calc(100% + 28px); }

.wp-block-cover-image.components-placeholder h2,
.wp-block-cover.components-placeholder h2 {
  color: inherit; }

.wp-block-cover-image.has-right-content .block-editor-rich-text__inline-toolbar,
.wp-block-cover-image.has-left-content .block-editor-rich-text__inline-toolbar,
.wp-block-cover.has-right-content .block-editor-rich-text__inline-toolbar,
.wp-block-cover.has-left-content .block-editor-rich-text__inline-toolbar {
  display: inline-block; }

.wp-block-cover-image .block-editor-block-list__layout,
.wp-block-cover .block-editor-block-list__layout {
  width: 100%; }

.wp-block-cover-image .block-editor-block-list__block,
.wp-block-cover .block-editor-block-list__block {
  color: #f8f9f9; }

.wp-block-cover-image .wp-block-cover__inner-container,
.wp-block-cover .wp-block-cover__inner-container {
  text-align: left; }

.wp-block-cover-image .wp-block-cover__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout,
.wp-block-cover .wp-block-cover__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }

.wp-block-cover-image.components-placeholder,
.wp-block-cover.components-placeholder {
  background: rgba(139, 139, 150, 0.1);
  min-height: 200px; }
  .is-dark-theme .wp-block-cover-image.components-placeholder, .is-dark-theme
  .wp-block-cover.components-placeholder {
    background: rgba(255, 255, 255, 0.15); }

.wp-block-cover-image .wp-block-cover__placeholder-color-palette,
.wp-block-cover .wp-block-cover__placeholder-color-palette {
  max-width: 290px;
  margin-top: 1em; }

[data-align="left"] .wp-block-cover-image,
[data-align="right"] .wp-block-cover-image, [data-align="left"]
.wp-block-cover,
[data-align="right"]
.wp-block-cover {
  max-width: 305px;
  width: 100%; }

.block-library-cover__reset-button {
  margin-left: auto; }

.block-library-cover__resize-container:not(.is-resizing) {
  height: auto !important; }

.wp-block-embed {
  margin-left: 0;
  margin-right: 0;
  clear: both; }
  @media (min-width: 600px) {
    .wp-block-embed {
      min-width: 360px; }
      .wp-block-embed.components-placeholder {
        min-width: 0; } }
  .wp-block-embed.is-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    min-height: 200px;
    text-align: center;
    background: #f8f9f9; }
    .wp-block-embed.is-loading p {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 13px; }
  .wp-block-embed .components-placeholder__error {
    word-break: break-word; }
  .wp-block-embed .components-placeholder__learn-more {
    margin-top: 1em; }

.block-library-embed__interactive-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0; }

.wp-block-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0; }
  .wp-block-file .wp-block-file__content-wrapper {
    flex-grow: 1; }
  .wp-block-file .wp-block-file__textlink {
    display: inline-block;
    min-width: 1em; }
    .wp-block-file .wp-block-file__textlink:focus {
      box-shadow: none; }
  .wp-block-file .wp-block-file__button-richtext-wrapper {
    display: inline-block;
    margin-left: 0.75em; }
  .wp-block-file .wp-block-file__copy-url-button {
    margin-left: 1em; }

.wp-block-freeform.block-library-rich-text__tinymce {
  /* Remove blue highlighting of selected images in WebKit */
  /* Image captions */
  /* WP Views */ }
  .wp-block-freeform.block-library-rich-text__tinymce p,
  .wp-block-freeform.block-library-rich-text__tinymce li {
    line-height: 1.8; }
  .wp-block-freeform.block-library-rich-text__tinymce ul,
  .wp-block-freeform.block-library-rich-text__tinymce ol {
    padding-left: 2.5em;
    margin-left: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce blockquote {
    margin: 0;
    box-shadow: inset 0 0 0 0 #e2e4e7;
    border-left: 4px solid #000;
    padding-left: 1em; }
  .wp-block-freeform.block-library-rich-text__tinymce pre {
    white-space: pre-wrap;
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 14px;
    color: #23282d; }
  .wp-block-freeform.block-library-rich-text__tinymce > *:first-child {
    margin-top: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce > *:last-child {
    margin-bottom: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce.mce-edit-focus {
    outline: none; }
  .wp-block-freeform.block-library-rich-text__tinymce a {
    color: #007fac; }
  .wp-block-freeform.block-library-rich-text__tinymce:focus a[data-mce-selected] {
    padding: 0 2px;
    margin: 0 -2px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #e5f5fa;
    background: #e5f5fa; }
  .wp-block-freeform.block-library-rich-text__tinymce code {
    padding: 2px;
    border-radius: 2px;
    color: #23282d;
    background: #f3f4f5;
    font-family: Menlo, Consolas, monaco, monospace;
    font-size: 14px; }
  .wp-block-freeform.block-library-rich-text__tinymce:focus code[data-mce-selected] {
    background: #e8eaeb; }
  .wp-block-freeform.block-library-rich-text__tinymce .alignright {
    /*rtl:ignore*/
    float: right;
    /*rtl:ignore*/
    margin: 0.5em 0 0.5em 1em; }
  .wp-block-freeform.block-library-rich-text__tinymce .alignleft {
    /*rtl:ignore*/
    float: left;
    /*rtl:ignore*/
    margin: 0.5em 1em 0.5em 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .wp-block-freeform.block-library-rich-text__tinymce .wp-more-tag {
    width: 96%;
    height: 20px;
    display: block;
    margin: 15px auto;
    outline: 0;
    cursor: default;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAADtgAAAAoBAMAAAA86gLBAAAAJFBMVEVMaXG7u7vBwcHDw8POzs68vLzGxsbMzMy+vr7AwMDQ0NDGxsYKLGzpAAAADHRSTlMA///zWf+/f///TMxNVGuqAAABwklEQVR4Ae3dMXLaQBTH4bfj8UCpx8hq0vgKvgFNemhT6Qo6gg6R+0ZahM2QLmyBJ99XWP9V5+o3jIUcLQEAAAAAAAAAAAAAAAAAAAAAAABQ8j0WL9lfTtlt18uNXAUA8O/KVtfa1tdcrOdSh9gCQAMlh1hMNbZZ1bsrsQWABsrhLRbz7z5in/32UbfUMUbkMQCAh5RfGYv82UdMdZ6HS2wjT2ILAI8r3XmM2B3WvM59vfO2xXYW2yYAENuPU8S+X/N67mKxzy225yaxBQCxLV392UdcvwV0jPVUj98ntkBWT7C7+9u2/V/vGtvXIWJ6/4rtbottWa6Ri0NUT/u72LYttrb97LHdvUXMxxrb8TO2W2TF1rYbbLG1bbGNjMi4+2Sbi1FsbbvNFlvbFtt5fDnE3d9sP1/XeIyV2Nr2U2/guZUuptNrH/dPI9eLB6SaAEBs6wPJf3/PNk9tYgsAYrv/8TFuzx/fvkFqGtrEFgDEdpcZUb7ejXy6ntrEFgDENvL6gsas4vbdyKt4DACI7TxElJv/Z7udpqFNbAFAbKduy2uU2trttM/x28UWAAAAAAAAAAAAAAAAAAAAAAAAAADgDyPwGmGTCZp7AAAAAElFTkSuQmCC);
    background-size: 1900px 20px;
    background-repeat: no-repeat;
    background-position: center; }
  .wp-block-freeform.block-library-rich-text__tinymce img::selection {
    background-color: transparent; }
  .wp-block-freeform.block-library-rich-text__tinymce div.mceTemp {
    -ms-user-select: element; }
  .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption {
    margin: 0;
    /* dl browser reset */
    max-width: 100%; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption a,
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption img {
      display: block; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption,
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption * {
      -webkit-user-drag: none; }
    .wp-block-freeform.block-library-rich-text__tinymce dl.wp-caption .wp-caption-dd {
      padding-top: 0.5em;
      margin: 0;
      /* browser dd reset */ }
  .wp-block-freeform.block-library-rich-text__tinymce .wpview {
    width: 99.99%;
    /* All IE need hasLayout, incl. 11 (ugh, not again!!) */
    position: relative;
    clear: both;
    margin-bottom: 16px;
    border: 1px solid transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview iframe {
      display: block;
      max-width: 100%;
      background: transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .mce-shim {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected="2"] .mce-shim {
      display: none; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .loading-placeholder {
      border: 1px dashed #e8eaeb;
      padding: 10px; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .wpview-error {
      border: 1px solid #e8eaeb;
      padding: 1em 0;
      margin: 0;
      word-wrap: break-word; }
      .wp-block-freeform.block-library-rich-text__tinymce .wpview .wpview-error p {
        margin: 0;
        text-align: center; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected] .loading-placeholder,
    .wp-block-freeform.block-library-rich-text__tinymce .wpview[data-mce-selected] .wpview-error {
      border-color: transparent; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview .dashicons {
      display: block;
      margin: 0 auto;
      width: 32px;
      height: 32px;
      font-size: 32px; }
    .wp-block-freeform.block-library-rich-text__tinymce .wpview.wpview-type-gallery::after {
      content: "";
      display: table;
      clear: both; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery img[data-mce-selected]:focus {
    outline: none; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery a {
    cursor: default; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery {
    margin: auto -6px;
    padding: 6px 0;
    line-height: 1;
    overflow-x: hidden; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-item {
    float: left;
    margin: 0;
    text-align: center;
    padding: 6px;
    box-sizing: border-box; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-caption,
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-icon {
    margin: 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery .gallery-caption {
    font-size: 13px;
    margin: 4px 0; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-1 .gallery-item {
    width: 100%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-2 .gallery-item {
    width: 50%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-3 .gallery-item {
    width: 33.33333%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-4 .gallery-item {
    width: 25%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-5 .gallery-item {
    width: 20%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-6 .gallery-item {
    width: 16.66667%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-7 .gallery-item {
    width: 14.28571%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-8 .gallery-item {
    width: 12.5%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery-columns-9 .gallery-item {
    width: 11.11111%; }
  .wp-block-freeform.block-library-rich-text__tinymce .gallery img {
    max-width: 100%;
    height: auto;
    border: none;
    padding: 0; }

div[data-type="core/freeform"] .block-editor-block-list__block-edit::before {
  transition: border-color 0.1s linear, box-shadow 0.1s linear;
  border: 1px solid #e2e4e7;
  outline: 1px solid transparent; }
  @media (prefers-reduced-motion: reduce) {
    div[data-type="core/freeform"] .block-editor-block-list__block-edit::before {
      transition-duration: 0s; } }

div[data-type="core/freeform"].is-selected .block-editor-block-list__block-edit::before {
  border-color: #b5bcc2;
  border-left-color: transparent; }

div[data-type="core/freeform"].is-hovered .block-editor-block-list__breadcrumb {
  display: none; }

div[data-type="core/freeform"] .editor-block-contextual-toolbar + div {
  margin-top: 0;
  padding-top: 0; }

div[data-type="core/freeform"].is-selected .block-library-rich-text__tinymce::after {
  content: "";
  display: table;
  clear: both; }

.mce-toolbar-grp .mce-btn.mce-active button,
.mce-toolbar-grp .mce-btn.mce-active:hover button,
.mce-toolbar-grp .mce-btn.mce-active i,
.mce-toolbar-grp .mce-btn.mce-active:hover i {
  color: #23282d; }

.mce-toolbar-grp .mce-rtl .mce-flow-layout-item.mce-last {
  margin-right: 0;
  margin-left: 8px; }

.mce-toolbar-grp .mce-btn i {
  font-style: normal; }

.block-library-classic__toolbar {
  display: none;
  width: auto;
  margin: 0 -14px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  top: 14px;
  transform: translateY(-14px);
  border: 1px solid #e2e4e7;
  border-bottom: none;
  padding: 0; }
  div[data-type="core/freeform"].is-selected .block-library-classic__toolbar,
  div[data-type="core/freeform"].is-typing .block-library-classic__toolbar {
    display: block;
    border-color: #b5bcc2; }
  .block-library-classic__toolbar .mce-tinymce {
    box-shadow: none; }
  @media (min-width: 600px) {
    .block-library-classic__toolbar {
      padding: 0; } }
  .block-library-classic__toolbar:empty {
    display: block;
    background: #f5f5f5;
    border-bottom: 1px solid #e2e4e7; }
    .block-library-classic__toolbar:empty::before {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
      font-size: 13px;
      content: attr(data-placeholder);
      color: #555d66;
      line-height: 37px;
      padding: 14px; }
  .block-library-classic__toolbar .mce-tinymce-inline,
  .block-library-classic__toolbar .mce-tinymce-inline > div,
  .block-library-classic__toolbar div.mce-toolbar-grp,
  .block-library-classic__toolbar div.mce-toolbar-grp > div,
  .block-library-classic__toolbar .mce-menubar,
  .block-library-classic__toolbar .mce-menubar > div {
    height: auto !important;
    width: 100% !important; }
  .block-library-classic__toolbar .mce-container-body.mce-abs-layout {
    overflow: visible; }
  .block-library-classic__toolbar .mce-menubar,
  .block-library-classic__toolbar div.mce-toolbar-grp {
    position: static; }
  .block-library-classic__toolbar .mce-toolbar-grp > div {
    padding: 1px 3px; }
  .block-library-classic__toolbar .mce-toolbar-grp .mce-toolbar:not(:first-child) {
    display: none; }
  .block-library-classic__toolbar.has-advanced-toolbar .mce-toolbar-grp .mce-toolbar {
    display: block; }

.wp-block-gallery li {
  list-style-type: none; }

.is-selected .wp-block-gallery {
  margin-bottom: 0; }

.blocks-gallery-grid.blocks-gallery-grid {
  margin-bottom: 0; }

.blocks-gallery-item figure:not(.is-selected):focus {
  outline: none; }

.blocks-gallery-item figure.is-selected {
  outline: 4px solid #0085ba; }

body.admin-color-sunrise .blocks-gallery-item figure.is-selected {
  outline: 4px solid #d1864a; }

body.admin-color-ocean .blocks-gallery-item figure.is-selected {
  outline: 4px solid #a3b9a2; }

body.admin-color-midnight .blocks-gallery-item figure.is-selected {
  outline: 4px solid #e14d43; }

body.admin-color-ectoplasm .blocks-gallery-item figure.is-selected {
  outline: 4px solid #a7b656; }

body.admin-color-coffee .blocks-gallery-item figure.is-selected {
  outline: 4px solid #c2a68c; }

body.admin-color-blue .blocks-gallery-item figure.is-selected {
  outline: 4px solid #82b4cb; }

body.admin-color-light .blocks-gallery-item figure.is-selected {
  outline: 4px solid #0085ba; }

.blocks-gallery-item figure.is-transient img {
  opacity: 0.3; }

.blocks-gallery-item .block-editor-rich-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .blocks-gallery-item .is-selected .block-editor-rich-text {
    right: 0;
    left: 0;
    margin-top: -4px; } }

.blocks-gallery-item .is-selected .block-editor-rich-text .block-editor-rich-text__inline-toolbar {
  top: 0; }

.blocks-gallery-item .is-selected .block-editor-rich-text figcaption {
  padding-top: 48px; }

.blocks-gallery-item .is-selected .block-library-gallery-item__move-menu,
.blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu {
  background: #fff;
  border: 1px solid rgba(66, 88, 99, 0.4);
  border-radius: 4px;
  transition: box-shadow 0.2s ease-out; }
  @media (prefers-reduced-motion: reduce) {
    .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu,
    .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu {
      transition-duration: 0s; } }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu:hover,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu:hover {
    box-shadow: 0 2px 10px rgba(25, 30, 35, 0.1), 0 0 2px rgba(25, 30, 35, 0.1); }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button {
    color: rgba(14, 28, 46, 0.62);
    padding: 2px;
    height: 24px; }
    .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover,
    .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button:not(:disabled):not([aria-disabled="true"]):not(.is-default):hover {
      box-shadow: none; }
    @media (min-width: 600px) {
      .columns-7 .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button,
      .columns-8 .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button, .columns-7
      .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button,
      .columns-8
      .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button {
        padding: 0;
        width: inherit;
        height: inherit; } }
  .blocks-gallery-item .is-selected .block-library-gallery-item__move-menu .components-button:focus,
  .blocks-gallery-item .is-selected .block-library-gallery-item__inline-menu .components-button:focus {
    color: inherit; }

.blocks-gallery-item .block-editor-rich-text figcaption {
  position: relative;
  overflow: hidden; }
  .blocks-gallery-item .block-editor-rich-text figcaption a {
    color: #fff; }

.block-library-gallery-item__move-menu,
.block-library-gallery-item__inline-menu {
  margin: 8px;
  display: inline-flex;
  z-index: 20; }
  .block-library-gallery-item__move-menu .components-button,
  .block-library-gallery-item__inline-menu .components-button {
    color: transparent; }
  @media (min-width: 600px) {
    .columns-7 .block-library-gallery-item__move-menu,
    .columns-8 .block-library-gallery-item__move-menu, .columns-7
    .block-library-gallery-item__inline-menu,
    .columns-8
    .block-library-gallery-item__inline-menu {
      padding: 2px; } }

.block-library-gallery-item__inline-menu {
  position: absolute;
  top: -2px;
  right: -2px; }

.block-library-gallery-item__move-menu {
  position: absolute;
  top: -2px;
  left: -2px; }

.blocks-gallery-item__move-backward,
.blocks-gallery-item__move-forward,
.blocks-gallery-item__remove {
  padding: 0; }

.blocks-gallery-item .components-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -9px; }

/**
 * Group: All Alignment Settings
 */
.wp-block[data-type="core/group"] .editor-block-list__insertion-point {
  left: 0;
  right: 0; }

.wp-block[data-type="core/group"] > .editor-block-list__block-edit > div > .wp-block-group.has-background > .wp-block-group__inner-container > .editor-inner-blocks {
  margin-top: -32px;
  margin-bottom: -32px; }

.wp-block[data-type="core/group"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] {
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px; }
  @media (min-width: 600px) {
    .wp-block[data-type="core/group"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] {
      padding-left: 58px;
      padding-right: 58px; } }

.wp-block[data-type="core/group"] > .editor-block-list__block-edit > div > .wp-block-group.has-background > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] {
  margin-left: -30px;
  width: calc(100% + 60px); }

/**
 * Group: Full Width Alignment
 */
.wp-block[data-type="core/group"][data-align="full"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0; }
  .wp-block[data-type="core/group"][data-align="full"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout {
    margin-left: 0;
    margin-right: 0; }

.wp-block[data-type="core/group"][data-align="full"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] {
  padding-right: 0;
  padding-left: 0;
  left: 0;
  width: 100%;
  max-width: none; }
  .wp-block[data-type="core/group"][data-align="full"] > .editor-block-list__block-edit > div > .wp-block-group > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] > .editor-block-list__block-edit {
    margin-left: 0;
    margin-right: 0; }

.wp-block[data-type="core/group"][data-align="full"] > .editor-block-list__block-edit > div > .wp-block-group.has-background > .wp-block-group__inner-container > .editor-inner-blocks > .editor-block-list__layout > .wp-block[data-align="full"] {
  width: calc(100% + 60px); }

.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/group"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks,
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/group"].is-selected > .block-editor-block-list__block-edit > [data-block] > .wp-block-group > .wp-block-group__inner-container > .block-editor-inner-blocks {
  padding: 14px; }

.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/group"].has-child-selected > .block-editor-block-list__block-edit > [data-block] > .wp-block-group:not(.has-background) > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout,
.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/group"].is-selected > .block-editor-block-list__block-edit > [data-block] > .wp-block-group:not(.has-background) > .wp-block-group__inner-container > .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-top: -28px;
  margin-bottom: -28px; }

[data-type="core/group"].is-selected .block-list-appender {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/group"].is-selected .has-background .block-list-appender {
  margin-top: 18px;
  margin-bottom: 18px; }

.wp-block-html {
  margin-bottom: 28px; }
  .wp-block-html .block-editor-plain-text {
    font-family: Menlo, Consolas, monaco, monospace;
    color: #23282d;
    padding: 0.8em 1em;
    border: 1px solid #e2e4e7;
    border-radius: 4px;
    max-height: 250px;
    /* Fonts smaller than 16px causes mobile safari to zoom. */
    font-size: 16px; }
    @media (min-width: 600px) {
      .wp-block-html .block-editor-plain-text {
        font-size: 13px; } }
    .wp-block-html .block-editor-plain-text:focus {
      box-shadow: none; }

.wp-block-image {
  position: relative; }
  .wp-block-image.is-transient img {
    opacity: 0.3; }
  .wp-block-image figcaption img {
    display: inline; }
  .wp-block-image .components-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -9px;
    margin-left: -9px; }

.wp-block-image .components-resizable-box__container {
  display: inline-block; }
  .wp-block-image .components-resizable-box__container img {
    display: block;
    width: 100%; }

.wp-block-image.is-focused .components-resizable-box__handle {
  display: block;
  z-index: 1; }

.block-editor-block-list__block[data-type="core/image"][data-align="center"] .wp-block-image {
  margin-left: auto;
  margin-right: auto; }

.block-editor-block-list__block[data-type="core/image"][data-align="center"][data-resized="false"] .wp-block-image > div {
  margin-left: auto;
  margin-right: auto; }

.edit-post-sidebar .block-library-image__dimensions {
  margin-bottom: 1em; }
  .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row {
    display: flex;
    justify-content: space-between; }
    .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__width,
    .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__height {
      margin-bottom: 0.5em; }
      .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__width input,
      .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__height input {
        line-height: 1.25; }
    .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__width {
      margin-right: 5px; }
    .edit-post-sidebar .block-library-image__dimensions .block-library-image__dimensions__row .block-library-image__dimensions__height {
      margin-left: 5px; }

.block-editor-block-list__block[data-type="core/image"] .block-editor-block-toolbar .block-editor-url-input__button-modal {
  position: absolute;
  left: 0;
  right: 0;
  margin: -1px 0; }
  @media (min-width: 600px) {
    .block-editor-block-list__block[data-type="core/image"] .block-editor-block-toolbar .block-editor-url-input__button-modal {
      margin: -1px; } }

[data-type="core/image"][data-align="center"] .block-editor-block-list__block-edit figure,
[data-type="core/image"][data-align="left"] .block-editor-block-list__block-edit figure,
[data-type="core/image"][data-align="right"] .block-editor-block-list__block-edit figure {
  margin: 0;
  display: table; }

[data-type="core/image"][data-align="center"] .block-editor-block-list__block-edit .block-editor-rich-text,
[data-type="core/image"][data-align="left"] .block-editor-block-list__block-edit .block-editor-rich-text,
[data-type="core/image"][data-align="right"] .block-editor-block-list__block-edit .block-editor-rich-text {
  display: table-caption;
  caption-side: bottom; }

[data-type="core/image"][data-align="wide"] figure img,
[data-type="core/image"][data-align="full"] figure img {
  width: 100%; }

[data-type="core/image"] .block-editor-block-list__block-edit figure.is-resized {
  margin: 0;
  display: table; }
  [data-type="core/image"] .block-editor-block-list__block-edit figure.is-resized .block-editor-rich-text {
    display: table-caption;
    caption-side: bottom; }

.wp-block-latest-comments.has-avatars .avatar {
  margin-right: 10px; }

.wp-block-latest-comments__comment-excerpt p {
  font-size: 14px;
  line-height: 1.8;
  margin: 5px 0 20px;
  padding-top: 0; }

.wp-block-latest-comments.has-avatars .wp-block-latest-comments__comment {
  min-height: 36px; }

.block-editor .wp-block-latest-posts {
  padding-left: 2.5em; }
  .block-editor .wp-block-latest-posts.is-grid {
    padding-left: 0; }

.wp-block-latest-posts li a > div {
  display: inline; }

.wp-block-legacy-widget__edit-container,
.wp-block-legacy-widget__preview {
  padding-left: 2.5em;
  padding-right: 2.5em; }

.wp-block-legacy-widget__edit-container .widget-inside {
  border: none;
  display: block;
  box-shadow: none; }

.wp-block-legacy-widget__update-button {
  margin-left: auto;
  display: block; }

.wp-block-legacy-widget__preview {
  overflow: auto; }

.wp-block-media-text {
  grid-template-areas: "media-text-media media-text-content" "resizer resizer";
  align-items: center; }

.wp-block-media-text.has-media-on-the-right {
  grid-template-areas: "media-text-content media-text-media" "resizer resizer"; }

.wp-block-media-text .__resizable_base__ {
  grid-area: resizer; }

.wp-block-media-text .editor-media-container__resizer {
  grid-area: media-text-media;
  width: 100% !important; }

.wp-block-media-text.is-image-fill .editor-media-container__resizer {
  height: 100% !important; }

.wp-block-media-text .block-editor-inner-blocks {
  word-break: break-word;
  grid-area: media-text-content;
  text-align: initial;
  padding: 0 8% 0 8%; }

.wp-block-media-text > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-editor-block-list__block {
  max-width: unset; }

figure.block-library-media-text__media-container {
  margin: 0;
  height: 100%;
  width: 100%; }

.wp-block-media-text .block-library-media-text__media-container img,
.wp-block-media-text .block-library-media-text__media-container video {
  vertical-align: middle;
  width: 100%; }

.editor-media-container__resizer .components-resizable-box__handle {
  display: none; }

.wp-block-media-text.is-selected:not(.is-stacked-on-mobile) .editor-media-container__resizer .components-resizable-box__handle {
  display: block; }

@media (min-width: 600px) {
  .wp-block-media-text.is-selected.is-stacked-on-mobile .editor-media-container__resizer .components-resizable-box__handle {
    display: block; } }

.editor-styles-wrapper .block-library-list ul,
.editor-styles-wrapper .block-library-list ol {
  padding-left: 1.3em;
  margin-left: 1.3em; }

.block-editor-block-list__block[data-type="core/more"] {
  max-width: 100%;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px; }

.block-editor .wp-block-more {
  display: block;
  text-align: center;
  white-space: nowrap; }
  .block-editor .wp-block-more input[type="text"] {
    position: relative;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #6c7781;
    border: none;
    box-shadow: none;
    white-space: nowrap;
    text-align: center;
    margin: 0;
    border-radius: 4px;
    background: #fff;
    padding: 6px 8px;
    height: 24px; }
    .block-editor .wp-block-more input[type="text"]:focus {
      box-shadow: none; }
  .block-editor .wp-block-more::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: 0;
    right: 0;
    border-top: 3px dashed #ccd0d4; }

.wp-block-navigation-menu .block-editor-block-list__layout,
.wp-block-navigation-menu {
  display: grid;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
  white-space: nowrap; }

.wp-block-navigation-menu__inserter-content {
  width: 350px;
  padding: 16px; }

.wp-block-navigation-menu-item__edit-container {
  display: grid;
  grid-auto-columns: -webkit-min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: center;
  white-space: nowrap; }

.wp-block-navigation-menu-item__edit-container {
  border: 1px solid #e2e4e7;
  width: 178px;
  padding-left: 1px; }

.wp-block-navigation-menu-item__edit-container .wp-block-navigation-menu-item__field {
  border-right: 1px solid #e2e4e7 !important;
  width: 140px;
  border: none;
  border-radius: 0;
  padding-left: 16px;
  min-height: 35px;
  line-height: 35px; }
  .wp-block-navigation-menu-item__edit-container .wp-block-navigation-menu-item__field, .wp-block-navigation-menu-item__edit-container .wp-block-navigation-menu-item__field:focus {
    color: #555d66; }

.wp-block-navigation-menu-item {
  font-family: "Noto Serif", serif;
  color: #0073af;
  font-weight: bold;
  font-size: 14px; }

.wp-block-navigation-menu-item__nofollow-external-link {
  display: block; }

.wp-block-navigation-menu-item__separator {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 0;
  margin-right: 0;
  border-top: 1px solid #e2e4e7; }

.components-popover:not(.is-mobile).wp-block-navigation-menu-item__dropdown-content {
  margin-top: -1px;
  margin-left: -4px; }

.wp-block-navigation-menu-item__dropdown-content .components-popover__content {
  padding: 8px 0; }

.wp-block-navigation-menu .block-editor-block-list__block[data-type="core/navigation-menu-item"] > .block-editor-block-list__block-edit > div[role="toolbar"] {
  display: none; }

.wp-block-navigation-menu .block-editor-block-list__block[data-type="core/navigation-menu-item"] > .block-editor-block-list__insertion-point {
  display: none; }

.block-editor-block-list__block[data-type="core/nextpage"] {
  max-width: 100%;
  margin-top: 28px;
  margin-bottom: 28px; }

.wp-block-nextpage {
  display: block;
  text-align: center;
  white-space: nowrap; }
  .wp-block-nextpage > span {
    font-size: 13px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    color: #6c7781;
    border-radius: 4px;
    background: #fff;
    padding: 6px 8px;
    height: 24px; }
  .wp-block-nextpage::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: 0;
    right: 0;
    border-top: 3px dashed #ccd0d4; }

.block-editor-rich-text__editable.wp-block-paragraph:not(.is-selected) [data-rich-text-placeholder]::after {
  display: inline-block;
  padding-right: 108px; }
  .wp-block .wp-block .block-editor-rich-text__editable.wp-block-paragraph:not(.is-selected) [data-rich-text-placeholder]::after {
    padding-right: 36px; }

.block-editor-block-list__block[data-type="core/paragraph"] p {
  min-height: 28px;
  line-height: 1.8; }

.wp-block-preformatted pre {
  white-space: pre-wrap; }

.block-editor-block-list__block[data-type="core/pullquote"][data-align="left"] .block-editor-rich-text p, .block-editor-block-list__block[data-type="core/pullquote"][data-align="right"] .block-editor-rich-text p {
  font-size: 20px; }

.wp-block-pullquote blockquote > .block-editor-rich-text p {
  font-size: 28px;
  line-height: 1.6; }

.wp-block-pullquote.is-style-solid-color {
  margin-left: 0;
  margin-right: 0; }
  .wp-block-pullquote.is-style-solid-color blockquote > .block-editor-rich-text p {
    font-size: 32px; }
  .wp-block-pullquote.is-style-solid-color .wp-block-pullquote__citation {
    text-transform: none;
    font-style: normal; }

.wp-block-pullquote .wp-block-pullquote__citation {
  color: inherit; }

.wp-block-quote__citation {
  font-size: 13px; }

.block-editor .wp-block-rss {
  padding-left: 2.5em; }
  .block-editor .wp-block-rss.is-grid {
    padding-left: 0; }

.wp-block-rss li a > div {
  display: inline; }

.wp-block-search .wp-block-search__input {
  border-radius: 4px;
  border: 1px solid #7e8993;
  color: rgba(14, 28, 46, 0.62);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px; }
  .wp-block-search .wp-block-search__input:focus {
    outline: none; }

.wp-block-search .wp-block-search__button {
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 -1px 0 #ccc;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px; }
  .wp-block-search .wp-block-search__button .wp-block-search__button-rich-text {
    padding: 6px 10px; }

.wp-block-shortcode {
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: rgba(139, 139, 150, 0.1);
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  margin-bottom: 28px; }
  .wp-block-shortcode label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 600;
    flex-shrink: 0; }
  .wp-block-shortcode .block-editor-plain-text {
    width: 80%;
    max-height: 250px; }
  .wp-block-shortcode .dashicon {
    margin-right: 8px; }

.wp-social-link {
  padding: 6px; }

.wp-block-social-links.is-style-pill-shape .wp-social-link {
  padding-left: 16px;
  padding-right: 16px; }

.wp-block-social-links div.editor-url-input {
  display: inline-block;
  margin-left: 8px; }

.wp-block-social-links .editor-block-list__layout {
  display: flex;
  justify-content: flex-start; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block {
  width: auto;
  padding-left: 0;
  padding-right: 0; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block > .block-editor-block-list__block-edit {
  margin-left: 0;
  margin-right: 0; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block > .block-editor-block-list__block-edit::before {
  border-right: none;
  border-top: none;
  border-bottom: none; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block.is-hovered:not(.is-navigate-mode) > .block-editor-block-list__block-edit::before {
  box-shadow: none; }

[data-type="core/social-links"].is-hovered .wp-block-social-links .block-editor-block-list__block-edit::before,
[data-type="core/social-links"].is-selected .wp-block-social-links .block-editor-block-list__block-edit::before,
[data-type="core/social-links"].has-child-selected .wp-block-social-links .block-editor-block-list__block-edit::before {
  border-color: transparent !important; }

[data-type="core/social-links"] .wp-block-social-links > .block-editor-inner-blocks > .block-editor-block-list__layout > .wp-block > .block-editor-block-list__block-edit > [data-block] {
  margin-top: 0;
  margin-bottom: 0; }

[data-type="core/social-links"] .wp-block-social-links .block-editor-block-list__insertion-point,
[data-type="core/social-links"] .wp-block-social-links .block-editor-block-list__breadcrumb,
[data-type="core/social-links"] .wp-block-social-links .block-editor-block-mover.block-editor-block-mover {
  display: none; }

.wp-block-social-links .block-list-appender {
  margin: 0; }
  .wp-block-social-links .block-list-appender .block-editor-button-block-appender {
    padding: 8px;
    outline: none;
    background: none; }

[data-type="core/social-links"][data-align="center"] .wp-block-social-links {
  justify-content: center; }

.block-editor-block-preview__content .wp-social-link:disabled {
  opacity: 1; }

.block-editor-block-preview__content [data-type="core/social-links"] {
  width: auto !important;
  display: inline-block; }

[data-type="core/social-links"]:not(.is-selected):not(.has-child-selected) .wp-block-social-links {
  min-height: 36px; }

[data-type="core/social-links"] .wp-social-link__is-incomplete {
  transition: transform 0.1s ease;
  transform-origin: center center; }

[data-type="core/social-links"]:not(.is-selected):not(.has-child-selected) .wp-social-link__is-incomplete {
  opacity: 0;
  transform: scale(0);
  width: 0;
  padding: 0;
  margin-right: 0; }

.wp-social-link.wp-social-link__is-incomplete {
  opacity: 0.5; }

.wp-block-social-links .is-selected .wp-social-link__is-incomplete,
.wp-social-link.wp-social-link__is-incomplete:hover,
.wp-social-link.wp-social-link__is-incomplete:focus {
  opacity: 1; }

[data-type="core/social-links"] .wp-social-link:focus {
  opacity: 1;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #007cba;
  outline: 2px solid transparent; }

.block-library-spacer__resize-container.is-selected {
  background: #f3f4f5; }

.block-library-spacer__resize-container {
  clear: both;
  margin-bottom: 28px; }

.edit-post-visual-editor p.wp-block-subhead {
  color: #6c7781;
  font-size: 1.1em;
  font-style: italic; }

.block-editor-block-list__block[data-type="core/table"][data-align="left"], .block-editor-block-list__block[data-type="core/table"][data-align="right"], .block-editor-block-list__block[data-type="core/table"][data-align="center"] {
  height: auto; }
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] table, .block-editor-block-list__block[data-type="core/table"][data-align="right"] table, .block-editor-block-list__block[data-type="core/table"][data-align="center"] table {
    width: auto; }
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="left"] th, .block-editor-block-list__block[data-type="core/table"][data-align="right"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="right"] th, .block-editor-block-list__block[data-type="core/table"][data-align="center"] td,
  .block-editor-block-list__block[data-type="core/table"][data-align="center"] th {
    word-break: break-word; }

.block-editor-block-list__block[data-type="core/table"][data-align="center"] {
  text-align: initial; }
  .block-editor-block-list__block[data-type="core/table"][data-align="center"] table {
    margin: 0 auto; }

.wp-block-table {
  margin: 0; }
  .wp-block-table table {
    border-collapse: collapse; }
  .wp-block-table td,
  .wp-block-table th {
    padding: 0;
    border: 1px solid; }
  .wp-block-table td.is-selected,
  .wp-block-table th.is-selected {
    border-color: #00a0d2;
    box-shadow: inset 0 0 0 1px #00a0d2;
    border-style: double; }
  .wp-block-table__cell-content {
    padding: 0.5em; }
  .wp-block-table__placeholder-form.wp-block-table__placeholder-form {
    text-align: left;
    align-items: center; }
  .wp-block-table__placeholder-input {
    width: 100px; }
  .wp-block-table__placeholder-button {
    min-width: 100px;
    justify-content: center; }

.block-editor .wp-block-tag-cloud a {
  display: inline-block;
  margin-right: 5px; }

.block-editor .wp-block-tag-cloud span {
  display: inline-block;
  margin-left: 5px;
  color: #8f98a1;
  text-decoration: none; }

.wp-block-text-columns .block-editor-rich-text__editable:focus {
  outline: 1px solid #e2e4e7; }

pre.wp-block-verse,
.wp-block-verse pre {
  color: #191e23;
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  padding: 1em;
  overflow: auto; }

.block-editor-block-list__block[data-align="center"] {
  text-align: center; }

.editor-video-poster-control .components-button {
  display: block;
  margin-right: 8px; }

.editor-video-poster-control .components-button + .components-button {
  margin-top: 1em; }

/**
 * Import styles from internal editor components used by the blocks.
 */
.block-editor-block-list__layout .reusable-block-edit-panel {
  align-items: center;
  background: #f8f9f9;
  color: #555d66;
  display: flex;
  flex-wrap: wrap;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 13px;
  position: relative;
  top: -14px;
  margin: 0 -14px;
  padding: 8px 14px;
  z-index: 61;
  border: 1px dashed rgba(145, 151, 162, 0.25);
  border-bottom: none; }
  .block-editor-block-list__layout .block-editor-block-list__layout .reusable-block-edit-panel {
    margin: 0 -14px;
    padding: 8px 14px; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__spinner {
    margin: 0 5px; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__info {
    margin-right: auto; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__label {
    margin-right: 8px;
    white-space: nowrap;
    font-weight: 600; }
  .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
    flex: 1 1 100%;
    font-size: 14px;
    height: 30px;
    margin: 4px 0 8px; }
  .block-editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
    flex-shrink: 0; }
  @media (min-width: 960px) {
    .block-editor-block-list__layout .reusable-block-edit-panel {
      flex-wrap: nowrap; }
      .block-editor-block-list__layout .reusable-block-edit-panel .reusable-block-edit-panel__title {
        margin: 0; }
      .block-editor-block-list__layout .reusable-block-edit-panel .components-button.reusable-block-edit-panel__button {
        margin: 0 0 0 5px; } }

.editor-block-list__layout .is-selected .reusable-block-edit-panel {
  border-color: rgba(66, 88, 99, 0.4);
  border-left-color: transparent; }
  .is-dark-theme .editor-block-list__layout .is-selected .reusable-block-edit-panel {
    border-color: rgba(255, 255, 255, 0.45);
    border-left-color: transparent; }

.block-editor-block-list__layout .reusable-block-indicator {
  background: #fff;
  border: 1px dashed #e2e4e7;
  color: #555d66;
  top: -14px;
  height: 30px;
  padding: 4px;
  position: absolute;
  z-index: 1;
  width: 30px;
  right: -14px; }

/**
 * Editor Normalization Styles
 *
 * These are only output in the editor, but styles here are NOT prefixed .editor-styles-wrapper.
 * This allows us to create normalization styles that are easily overridden by editor styles.
 */
.editor-styles-wrapper [data-block] {
  margin-top: 28px;
  margin-bottom: 28px; }
