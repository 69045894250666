/**
 * Colors
 */
/**
 * Breakpoints & Media Queries
 */
/**
 * Often re-used variables
 */
/**
 * Breakpoint mixins
 */
/**
 * Long content fade mixin
 *
 * Creates a fading overlay to signify that the content is longer
 * than the space allows.
 */
/**
 * Button states and focus styles
 */
/**
 * Applies editor left position to the selector passed as argument
 */
/**
 * Applies editor right position to the selector passed as argument
 */
/**
 * Styles that are reused verbatim in a few places
 */
/**
 * Allows users to opt-out of animations via OS-level preferences.
 */
/**
 * Reset default styles for JavaScript UI based pages.
 * This is a WP-admin agnostic reset
 */
/**
 * Reset the WP Admin page styles for Gutenberg-like pages.
 */
.block-editor-format-toolbar__image-container-content {
  display: flex; }
  .block-editor-format-toolbar__image-container-content .components-icon-button {
    height: 52px;
    align-self: flex-end; }

.block-editor-format-toolbar__image-container-value {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-format-toolbar__image-container-value.components-base-control .components-base-control__field {
    margin-bottom: 0; }

.block-editor-format-toolbar__link-container-content {
  display: flex; }

.block-editor-format-toolbar__link-container-value {
  margin: 7px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 150px;
  max-width: 500px; }
  .block-editor-format-toolbar__link-container-value.has-invalid-link {
    color: #d94f4f; }
